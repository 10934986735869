	a.nav-link{
		&.active{
			background: $orange !important;
			color: $white !important;
		}
		color: $orange !important;
	}	


	.contesta-input-code{
		max-width: 300px;
		text-align: center;
		margin: 15px auto;
		line-height: 1em;
		small{
			text-transform: uppercase;
			font-size: .75em;
		}
	}

.popover.bottom .arrow {
    left:90% !important;
}
form.login-form{
	>div{
		margin: 8px;
	}
}